<template>
  <div style="background-color: #eff5fb; min-height: 100vh">
    <Nav-bar />
    <h1 style="text-align: center">
      Order {{ this.IdOrder != -1 ? "#" + this.IdOrder : "" }}
    </h1>
    <div class="container-fluid list-container">
      <div class="title">
        <div class="products-container">
          <div class="product-content">
            <table class="table-list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th style="text-align: right">Price</th>
                </tr>
              </thead>
              <tbody v-for="(product, i) in Order.Products" :key="product.Id + i">
                <tr>
                  <td>{{ product.Name }}</td>
                  <td style="text-align: right">
                    {{ (product.Price * 1).toFixed(2) }} $
                  </td>
                </tr>
                <tr style="font-size: 10px; padding 0px 10px" v-if="product.Extra.length > 0">
                  <td>
                    Extra: {{ product.Extra.map((e) => e.Name).join(", ") }}
                  </td>
                  <td style="text-align: right">
                    {{
                      product.Extra.map((e) => Number(e.Price))
                        .reduce((p, i) => p + i, 0)
                        .toFixed(2)
                    }}
                    $
                  </td>
                </tr>
                <tr style="font-size: 10px; padding 0px 10px" v-if="product.Methods !== ''">
                  <td colspan="2">Methods: {{ product.Methods }}</td>
                </tr>

                <tr>
                  <td colspan="2" style="text-align: right">
                    <div style="
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        diplay: flex;
                        justify-content: center;
                      "></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="footer-list">
          <h5>Subtotal: {{ total }} $</h5>

          <!-- <h5 @click="tip = !tip" style="cursor: pointer; user-select: none">
            Add tips (20%) <input type="checkbox" v-model="tip" />
          </h5> -->
          <h5>
            Sales tax (VAT):
            {{ ((VAT / 100) * total).toFixed(2) }}$
          </h5>
          <!-- <h5>Tips: {{ tip ? ((20 / 100) * total * 1).toFixed(2) : 0 }}</h5> -->
          <hr />
          <h4>
            TOTAL:
            {{ tip? calcVAT(total, VAT): calcVAT(total, VAT) }}$
          </h4>
        </div>
        <div class="footer-btn">
          <el-button type="success" @click="endOrder()">Invoice</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Nav from "@/pages/frontend/Nav.vue";
import Methods from "@/pages/frontend/order/Methods.vue";
import Extra from "@/pages/frontend/order/Extra.vue";

export default {
  name: "Order",
  components: {
    "Nav-bar": Nav,
    "Methods-dialog": Methods,
    "Extra-dialog": Extra,
  },
  extends: BasePage,
  data() {
    return {
      IdOrder: -1,
      btnName: "",
      IdTable: null,
      mode: "add",
      loadingVisible: false,
      printed: false,
      Order: {},
      editMode: false,
      VAT: 0,
      total: 0,
      tip: false,
      current_cat: null,
      Info: {
        Category: [],
        Methods: [],
        Extra: [],
      },
      currentRow: null,
      connection: null,
    };
  },

  methods: {
    async endOrder() {
      let id = this.IdOrder;
      let table = this.Order.IdTable;
      this.$confirm("Are you sure you want to finish this order?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Close",
        type: "warning",
      })
        .then(async () => {
          let data = await this.post("order/close_order", {
            Id: id,
            IdTable: table,
          });
          this.print();
          // this.print();
          await this.$router.push("/app/tables");
          await this.$router.go(0);
          this.$message({
            type: "success",
            message: "Order ended!",
          });
        })
        .catch(() => { });
    },

    calcVAT(total, vat) {
      vat = vat / 100 + 1;
      let result = vat * total;

      return result.toFixed(2);
    },
    async refresh_info() {
      this.loadingVisible = true;
      if (this.mode === "edit") {
        const res = await this.post("order/get_order_by_id", {
          Id: this.IdOrder,
        });

        this.Order = {
          ClosedAt: res.Order.ClosedAt,
          CreatedAt: res.Order.CreatedAt,
          Id: res.Order.Id,
          IdTable: res.Order.IdTable,
          Products: res.Order.Products,
        };
      } else {
        let obj = {
          ClosedAt: 0,
          CreatedAt: 0,
          IdTable: this.IdTable,
          Products: [],
        };
        this.Order = obj;
      }
      let total = this.Order.Products
        ? this.Order.Products.map((p) => {
          let price = Number(p.Price);
          let extra = p.Extra
            ? p.Extra.map((e) => Number(e.Price)).reduce((e, i) => e + i, 0)
            : 0;
          return price + extra;
        })
          .reduce((p, i) => p + i, 0)
          .toFixed(2)
        : 0;
      this.total = total;
    },

    async print() {
      let date_raw = this.Order.CreatedAt.split(" ");
      let c = date_raw[0].split("-");
      let createdAt = `${c[1]}-${c[2]}-${c[0]}`;
      let hrs = date_raw[1];
      let data = {
        order_number: this.Order.Id,
        table: this.Order.IdTable,
        createdAt: createdAt,
        time: hrs,
        products: this.Order.Products,
        vat: ((this.VAT / 100) * this.total).toFixed(2),
        subtotal: this.total,
        tips: this.tip ? this.tip : 0,
        total: this.calcVAT(this.total, this.VAT),
      };

      let response = await this.post("order/payment_print", {
        OrderId: data.order_number,
        Table: data.table,
        Products: data.products,
        Vat: data.vat,
        CreatedAt: data.createdAt,
        Subtotal: data.subtotal,
        Tips: data.tips,
        Time: data.time,
        Total: data.total,
      });

      console.log(response);
    },

    async get_info() {
      let response = await this.post("order/get_info_for_dialog");
      response.methods.forEach((methods) => {
        this.Info.Methods.push(methods);
      });

      response.extra.forEach((extra) => {
        this.Info.Extra.push(extra);
      });
      let vat = await this.post("order/get_vat");
      this.VAT = Number(vat.VAT[0].Value);
    },
  },
  mounted() {
    this.IdOrder = !this.$route.params.id.startsWith("new")
      ? this.$route.params.id
      : -1;

    if (this.IdOrder === -1) {
      this.mode = "add";
      this.btnName = "Place Order";
      this.IdTable = this.$route.params.id.replace("new", "");
    } else {
      this.mode = "edit";
      this.btnName = "Edit Order";
    }

    this.refresh_info();
    this.get_info();
  },
};
</script>

<style scoped>
.table-list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.list-btn {
  width: 70px;
  padding: 3px 15px;
  text-align: center;
  font-size: 10px;
}

.category-container {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 200px;
}

.list-container {
  margin: 0;
  padding: 25px 5px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  background-color: #eff5fb;
  gap: 5px;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}

.products-container {
  margin: 0;
  padding: 10px;
  width: 100%;
  height: 40vh;
  /* margin-top: 50px; */
  border-radius: 4px;
  background-color: #dde9f5;
}

.product-content {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #dde9f5;
  height: 100%;
}

.product-title {
  height: 60px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat-btn {
  background-color: #6493c2;
  border-color: #6493c2;
  width: 160px;
  height: 60px;
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.footer-list {
  width: 400px;
  text-align: right;
  margin-top: 15px;
}

@media only screen and (max-width: 600px) {
  .cat-btn {
    width: 130px;
    height: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-list {
    width: 100%;
    margin-right: 10px;
    padding: 10px;
  }

  .title {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  .category-container {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .products-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

.footer-btn {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
</style>




